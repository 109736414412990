article {
    margin-top: 32px;
    h2 {
        font-size: 28px;
        margin: 32px 0 8px 0;
        @include phone {
            font-size: 24px;
        }
    }
    h3 {
        font-size: 24px;
        margin: 32px 0 8px 0;
        @include phone {
            font-size: 20px;
        }
    }
    h4 {
        font-size: 20px;
        margin: 32px 0 8px 0;
        @include phone {
            font-size: 18px;
        }
    }
    > div {
        overflow-x: auto;
    }
    p {
        font-size: 20px;
        line-height: 32px;
        word-break: break-word;
        @include phone {
            font-size: 18px;
            line-height: 28px;
        }
    }
    a {
        color: #292929;
        &:hover {
            color: black;
        }
    }
    ol, ul {
        margin: 32px 0;
    }
    strong {
        font-weight: 600;
    }
    img {
        width: 100%;
    }
    blockquote {
        line-height: 28px;
    }
    .imageblock {
        margin: 32px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 14px;
            color: #757575;
            margin-top: 16px;
        }
    }
    .listingblock {
        margin: 32px 0;
    }
    .quoteblock {
        margin: 32px 0;
        background: #f5f5f5;
        padding: 32px;
        border-radius: 8px;
        .attribution {
            margin-top: 16px;
            font-size: 14px;
        }
    }
    .paragraph{
        margin: 32px 0;
    }
    pre {
        background: #F8F9FC;
        padding: 8px;
        border-radius: 4px;
    }
    table {
        margin: 32px 0;
        caption {
            text-align: left;
            margin-bottom: 16px;
            font-weight: 700;
        }
        p {
            font-size: 14px;
            line-height: 24px;
            white-space: normal;
        }
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    th, td {
        padding: 8px;
    }
    .hdlist1 {
        display: inline-flex;
        width: initial;
        border-radius: 4px;
        margin-top: 32px;
        margin-bottom: 8px;
        font-weight: 700;
    }
    p code {
        background: #eff3f8;
        padding: 0 8px;
        display: inline-flex;
        width: initial;
        border-radius: 4px;
        margin: 2px 0;
    }
    a {
        color: $pink;
        &:hover {
            color: $darkPink;
        }
    }
    @include phone {
        .videoblock iframe {
            width: 100%;
            height: 240px;
        }
    }
}
