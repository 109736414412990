// Colors
$lightBlue: #78B4D7;
$blue: #0E2B71;
$mediumBlue: #0A1E50;
$darkBlue: #061332;
$bgMediumBlue: #081840;
$bgDarkBlue: #091B49;
$pink: #DB2777;
$darkPink: #bb2267;
$red: #E62832;
$mediumRed: #AE1920;
$darkRed: #9B1919;
$white: #FFFFFF;
$lightGray: #c5c5c5;
$darkGray: #4A4A4A;
$border: #E7E7E7;

// Fonts
$font-size-small: 0.75rem;     /* 12px */
$font-size-base: 1rem;         /* 16px, base */
$font-size-medium: 1.0625rem;  /* 17px */
$font-size-large: 1.25rem;     /* 20px */
$font-size-xlarge: 1.375rem;   /* 22px */
$font-size-xxlarge: 1.5rem;    /* 24px */
$font-size-heading: 2.5rem;    /* 40px */
$font-size-hero: 4rem;         /* 64px */

// Border
$border-radius: 8px;

// Size
$size-xs: 8px;
$size-s: 16px;
$size-md: 32px;
$size-lg: 64px;
$size-xl: 128px;

// Space
$space-xs: 8px;
$space-s: 16px;
$space-md: 32px;
$space-lg: 64px;
$space-xl: 128px;
