// Commons imports
// -------

// Abstracts partials
@import "utils/abstracts/__abstracts-dir";

// Base partials
@import "utils/base/__base-dir";

// Components partials
@import "utils/components/__components-dir";

// Layout partials
@import "utils/layouts/__layouts-dir";

// Pages partials
@import "utils/pages/__pages-dir";
