.circle-group {
    position: absolute;
    left: 63%;
    @include tablet-landscape {
        left: 94%;
    }
    @include phone {
        left: 118%;
    }
    &__xs {
        border: 1px solid white;
        width: 176px;
        height: 176px;
        opacity: .25;
    }
    &__sm {
        width: 280px;
        height: 280px;
        &:before {
            position: absolute;
            content: '';
            height: 100%; 
            width: 100%; 
            border: 1px dashed $pink;
            border-radius: inherit;
            animation: spin 30s linear infinite;
        }
    }
    &__md {
        border: 1px solid white;
        width: 400px;
        height: 400px;
        opacity: .5;
    }
    &__lg {
        width: 544px;
        height: 544px;
        opacity: .75;
        &:before {
            position: absolute;
            content: '';
            height: 100%; 
            width: 100%; 
            border: 1px dashed white;
            border-radius: inherit;
            animation: spin 50s linear infinite;
        }
    }
}
.circle {
    @include flexbox;
    border-radius: 25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@keyframes spin { 
    100% { 
      transform: rotateZ(360deg); 
    }
}