// Flexbox
.flexbox {@include flexbox;}
.ff-wrap {
    flex-flow: wrap;
    height: 20px;
    overflow: hidden;
}
.jc-c {justify-content: center;}

// Space
.mgt-64 {margin-top: 64px;}
.p80-0 {padding: 80px 0;}

// Colors
.bg-gray {background: #f5f5f7;}

// Others
.d-none {display: none!important;}