.author {
    @include flexbox;
    font-size: 14px;
    margin-bottom: 16px;
    @include phone {
        flex-direction: column;
        align-items: initial;
    }
    &__avatar {
        height: 32px;
        width: 32px;
        border-radius: 5rem;
        margin-right: 8px;
    }
    &__avatar-lg {
        height: 40px;
        width: 40px;
    }
    &__text {
        @include flexbox;
        color: #292929;
        opacity: 0.5;
        text-decoration: none;
        &:hover {
            opacity: 1;
        }
    }
    &__date {
        @include flexbox;
        color: #292929;
        opacity: 0.5;
    }
    &__time {
        @include flexbox;
        color: #292929;
        opacity: 0.5;
    }
    span {
        display: flex;
        background: #8d8d8d;
        height: 4px;
        width: 4px;
        border-radius: 5rem;
        margin: 0 8px;
        &:first-child {
            @include phone {
                display: none;
            }
        }
    }
    > div:first-child {
        @include phone {
            margin-bottom: 8px;
        }
    }
}