.btn {
    @include flexbox;
    justify-content: center;
    height: 40px;
    font-weight: 500;
    padding: 0 32px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: 0.3s;
}
.btn__primary {
    color: $white;
    background: $pink;
    &:hover {
        color: $white;
        text-decoration: none;
        background: $darkPink;
    }
}
.btn__secondary {
    color: white;
    background: none;
    border: 1px solid #8d8d8d;
    &:hover {
        color: $white;
        text-decoration: none;
        background: #61D500;
    }
}
.scroll-up {
    position: fixed;
    bottom: 32px;
    right: 32px;
    display: flex;
    font-size: 32px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    padding: 7px;
    @include phone {
        display: none!important;
    }
}