#progress-display {
    position: fixed;
    width: 100%;
    top: 80px;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #FFF;
    display: none;
    @include phone {
        top: 0;
    }
}
#progress-over,
#progress-impend {
    float: left;
    top: 0;
    left: 0;
    height: 4px;
    margin: 0;
    padding: 0;
}
#progress-over {
    background-color: $pink;
    width: 0%;
}
#progress-impend {
    background-color: black;
    opacity: .1;
    width: 100%;
}
#progress-time {
    margin-right: 4px;
}