.wrapper {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 32px;
    @include phone {
        padding: 0 24px;
    }
}
.wrapper-lg {
    max-width: 1216px;
    margin: 0 auto;
    padding: 0 32px;
    @include phone {
        padding: 0 24px;
    }
}