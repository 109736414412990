.post {
	padding: 64px 0;
    @include phone {
        padding: 32px 0;
    }
	&__content {
		grid-column: span 12;
	}
	&__time {
		@include flexbox;
	}
	&__title {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
	}
    &__line {
        background: #e7e7e7;
        border: 0;
        display: block;
        height: 1px;
        width: 100%;
        margin: 24px 0;
    }
	&__cover {
        height: 500px;
        width: 100%;
        overflow: hidden;
        background: white;
        border-radius: 8px;
        margin-top: 16px;
        border: 1px solid #e7e7e7;
        @include tablet-landscape {
            height: 450px;
        }
        @include tablet-portrait {
            height: 320px;
        }
        @include phone {
            height: 200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}