main {
    padding-top: 80px;
    @include phone {
        padding-top: 0;
    }
}
body {
    font-family: 'Poppins', sans-serif;
    font-size: $font-size-base;
    font-weight: 400;
    color: #292929;
}
h2 {
    font-size: 32px;
    font-weight: 600;
}
.subtitle {
    font-size: 16px;
    color: #8d8d8d;
    margin-bottom: 32px;
}
