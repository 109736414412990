/*
This file is used to contain all base imports.
Files inside this folder can contain global styles used in the project.
*/

//Import Base files
@import "responsive";
@import "fonts";
@import "general";
@import "grid";
@import "reset";
@import "wrapper";