.card-list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 32px;
    @include phone {
        grid-gap: 24px;
    }
}
.card {
    grid-column: span 4;
    text-decoration: none;
    transition: all .2s ease;
    border-radius: 8px;
    color: #292929;
    background: white;
    overflow: hidden;
    box-shadow: 0 10px 21px rgba(173, 178, 197, .17);
    -webkit-box-shadow: 0 10px 21px rgba(173, 178, 197, .17);
    @include tablet-landscape {
        grid-column: span 6;
    }
    @include phone {
        grid-column: span 12;
    }
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, .08);
        .card__cover img {
            -webkit-transform:scale(1.10); /* Safari et Chrome */
            -moz-transform:scale(1.10); /* Firefox */
            -ms-transform:scale(1.10); /* Internet Explorer 9 */
            -o-transform:scale(1.10); /* Opera */
            transform:scale(1.10);
        }
    }
    &__cover {
        height: 192px;
        width: 100%;
        overflow: hidden;
        background: white;
        border-bottom: 1px solid #e7e7e7;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            -webkit-transition: all 1s ease; /* Safari et Chrome */
            -moz-transition: all 1s ease; /* Firefox */
            -ms-transition: all 1s ease; /* Internet Explorer 9 */
            -o-transition: all 1s ease; /* Opera */
            transition: all 1s ease;
        }
    }
    &__infos {
        padding: 24px;
    }
    &__avatar-lg {
        height: 80px;
        width: 80px;
        border-radius: 5rem;
        margin-bottom: 16px;
    }
    &__author-lg {
        font-size: 16px;
        color: #292929;
        opacity: 0.5;
        text-decoration: none;
        &:hover {
            opacity: 1;
        }
    }
    &__tag {
        .tag {
            transition: all .5s ease;
            &:hover {
                -webkit-transform: scale(1.10); /* Safari et Chrome */
                -moz-transform: scale(1.10); /* Firefox */
                -ms-transform: scale(1.10); /* Internet Explorer 9 */
                -o-transform: scale(1.10); /* Opera */
                transform: scale(1.10);
            }
        }
    }
    &__title {
        color: black;
        font-size: 24px;
        font-weight: 700;
        margin: 16px 0;
        line-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.featured {
    grid-column: span 6;
    @include tablet-portrait {
        grid-column: span 12;
    }
    .card {
        border: 1px solid #d5d5d5;
        padding: 24px;
        display: flex;
        flex-direction: column;
        &__infos {
            padding: 0;
            padding-top: 24px;
        }
        &__cover {
            height: 320px;
            @include phone {
                height: 200px;
            }
        }
    }
}
.last {
    grid-column: span 6;
    @include tablet-portrait {
        grid-column: span 12;
    }
    .card {
        @include flexbox;
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            box-shadow: none;
        }
        @include phone {
            flex-direction: column;
        }
        &__cover {
            width: 260px;
            height: 160px;
            border-right: 1px solid #e7e7e7;
            @include tablet-landscape {
                width: 110px;
                height: 158px;
            }
            @include tablet-portrait {
                width: 50%;
                height: 140px;
            }
            @include phone {
                width: 100%;
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
        &__infos {
            padding: 16px 15px;
            width: calc(100% - 260px);
            @include tablet-landscape {
                width: calc(100% - 134px);
            }
            @include phone {
                width: 100%;
            }
        }
        &__title {
            font-size: 18px;
            margin: 8px 0;
            line-height: 24px;
        }
    }
}
