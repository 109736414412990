// Desktop first responsive

// applies to large devices (desktop)
@mixin desktop-up {
    @media (max-width: 1200px) {@content;}
}
// applies to medium devices (tablets -> landscape)
@mixin tablet-landscape {
    @media (max-width: 1024px) {@content;}
}
// applies to medium devices (tablets -> portrait)
@mixin tablet-portrait {
    @media (max-width: 768px) {@content;}
}
// applies to medium devices (tablets -> portrait)
@mixin phone {
    @media (max-width: 576px) {@content;}
}
