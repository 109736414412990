.lb_posts-list {
    padding: $space-xl 0 0 0;
}
.lb_posts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $space-md;
}
.lb_post-head {
    position: relative;
    display: flex;
    margin-left: $space-md;
    .lb_post-img {
        width: 100%;
        height: 290px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .lb_post-tags {
        display: inline-table;
        position: absolute;
        bottom: $space-md;
        left: -$space-md;
        font-family: "Space Mono";
        text-transform: uppercase;
        font-weight: 700;
        a {
            display: inline-flex;
            align-items: center;
            border-radius: $border-radius;
            background: rgba(234, 33, 46, 0.3);
            padding: $space-xs;
            text-decoration: none;
            color: $white;
            height: 16px;
            margin: 0 $space-xs $space-xs 0;
            font-size: 12px;
            -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
            &:hover {
                background: rgba(234, 33, 46, 0.6);
            }
        }
    }
}
.lb_post-body {
    background: rgba(37, 35, 34, 0.2);
    padding: $space-md;
    border-radius: $border-radius;
    margin-top: -$space-md;
    z-index: 2;
    position: relative;
    width: calc(100% - 96px);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    max-height: 192px;
    display: flex;
    flex-direction: column;
    .lb_post-title {
        font-size: 24px;
        margin-bottom: $space-md;
        line-height: 32px;
        text-decoration: none;
        color: $pink;
        display: flex;
        height: 64px;
        overflow: hidden;
        &:hover {
            color: $darkPink;
        }
    }
    .lb_post-thumb {
        position: relative;
        margin-right: $space-xs;
        .lb_post-initial {
            width: $size-md;
            height: $size-md;
            border-radius: 50%;
            background: $darkGray;
        }
        img {
            &:first-child {
                width: $size-md;
                height: $size-md;
                border-radius: 50%;
            }
            &:nth-child(2) {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
                bottom: -4px;
                right: -4px;
                object-fit: cover;
            }
        }
    }
    .lb_post-author {
        text-decoration: none;
        text-transform: capitalize;
        color: $darkGray;
        &:hover {
            border-bottom: 1px solid $darkGray;
        }
    }
    .lb_post-date {
        font-family: "Space Mono";
        font-weight: 700;
        line-height: 24px;
    }
    .lb_post-language img {
        height: 16px;
        margin: 0 $space-xs $space-xs 0;
    }
}
