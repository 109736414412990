.footer {
    background: $darkBlue;
    color: white;
    padding: 54px 128px;
    @include tablet-landscape {
        padding: 32px;
    }
    &__part {
        @include flexbox;
        justify-content: space-between;
        &:first-child {
            border-bottom: 1px solid #ffffff4D;
            padding-bottom: 32px;
        }
        &:last-child {
            padding-top: 32px;
            @include phone {
                flex-direction: column;
                align-items: flex-start;
            }
            > p {
                @include phone {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
