.cover {
    @include flexbox;
    justify-content: space-between;
    padding: 160px 128px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(-90deg, $darkBlue, $mediumBlue, $mediumBlue, $darkBlue);
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    @include tablet-landscape {
        padding: 0 64px;
        height: 500px;
    }
    @include phone {
        padding: 0 24px;
    }
    &__part {
        z-index: 1
    }
    &__subtitle {
        font-size: $size-s;
        color: $lightGray;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-weight: 600;
        span {
            color: $pink;
            margin-right: 8px;

        }
        @include tablet-landscape {
            font-size: 28px;
        }
        @include tablet-portrait {
            font-size: 26px;
        }
        @include phone {
            font-size: 24px;
        }
    }
    &__title {
        color: white;
        font-size: 4rem;
        line-height: 5rem;
        font-weight: 700;
        margin-bottom: 32px;
        @include tablet-landscape {
            font-size: 64px;
            line-height: 80px;
        }
        @include tablet-portrait {
            font-size: 54px;
            line-height: 64px;
        }
        @include phone {
            font-size: 56px;
            line-height: 64px;
        }
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}